import AppApi from '@/backend/Api';
import { AppApiResponse, Notification } from '@/types';
import { AxiosRequestConfig } from 'axios';

class NotificationService {
  getNotifications(): Promise<Notification[]> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/notifications',
        method: 'GET'
      };

      AppApi.sendRequest<Notification[]>(request)
        .then((data: AppApiResponse<Notification[]>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getNotification(notificationId: number): Promise<Notification> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/notifications/${notificationId}`,
        method: 'GET'
      };

      AppApi.sendRequest<Notification>(request)
        .then((data: AppApiResponse<Notification>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  saveNotification(notification: Notification): Promise<Notification> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/notifications',
        method: 'POST',
        data: notification
      };

      AppApi.sendRequest<Notification>(request)
        .then((data: AppApiResponse<Notification>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateNotification(notification: Notification): Promise<Notification> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/notifications',
        method: 'PUT',
        data: notification
      };

      AppApi.sendRequest<Notification>(request)
        .then((data: AppApiResponse<Notification>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendNotification(notificationId: number): Promise<Notification> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/notifications/send',
        method: 'POST',
        data: { notificationId }
      };

      AppApi.sendRequest<Notification>(request)
        .then((data: AppApiResponse<Notification>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteNotification(notificationId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/notifications/${notificationId}`,
        method: 'DELETE'
      };

      AppApi.sendRequest<string>(request)
        .then((data: AppApiResponse<string>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default new NotificationService();
