import AppApi from '@/backend/Api';
import { AxiosRequestConfig } from 'axios';

class FileService {
  getRemoteFileAsBinary(fileId: string): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/files/${fileId}/remote`,
        method: 'GET',
        responseType: 'arraybuffer'
      };

      AppApi.getFileAsBinary<ArrayBuffer>(request)
        .then((data: ArrayBuffer) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getRemoteThumbnailAsBinary(fileId: string): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/files/${fileId}/remote/thumbnail`,
        method: 'GET',
        responseType: 'arraybuffer'
      };

      AppApi.getFileAsBinary<ArrayBuffer>(request)
        .then((data: ArrayBuffer) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default new FileService();
