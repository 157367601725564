import AppApi from '@/backend/Api';
import { AppApiResponse } from '@/types';
import { AxiosRequestConfig } from 'axios';

class CommonService {
  sendSupportRequest(formData: FormData): Promise<String> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/common/support',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      };

      AppApi.sendRequest<String>(request)
        .then((data: AppApiResponse<String>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  uploadFile(_formData: FormData): Promise<string> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/common/file/upload',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: _formData
      };

      AppApi.sendRequest<string>(request)
        .then((data: AppApiResponse<string>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default new CommonService();
