import NotificationService from '@/backend/service/NotificationService';
import { Notification } from '@/types';
import findIndex from 'lodash/findIndex';
import { defineStore } from 'pinia';

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notifications: [] as Notification[],
    existedNotificationLangs: [] as string[]
  }),
  getters: {
    getNotifications(state) {
      return state.notifications;
    },
    getNotificationById(state) {
      return (notificationId: number): Notification | undefined => {
        return state.notifications.find((notification) => notification.id === notificationId);
      };
    }
  },
  actions: {
    fetchNotifications() {
      return new Promise((resolve, reject) => {
        NotificationService.getNotifications()
          .then((listNotifications: Notification[]) => {
            this.notifications = listNotifications;
            resolve(listNotifications);
          })
          .catch((error) => reject(error));
      });
    },
    fetchNotification(notificationId: number): Promise<Notification> {
      return new Promise((resolve, reject) => {
        NotificationService.getNotification(notificationId)
          .then((existedNotification: Notification) => {
            resolve(existedNotification);
          })
          .catch((error) => reject(error));
      });
    },
    createNotification(notification: Notification): Promise<Notification> {
      return new Promise((resolve, reject) => {
        NotificationService.saveNotification(notification)
          .then((createdNotification: Notification) => {
            this.notifications.push(createdNotification);
            resolve(createdNotification);
          })
          .catch((error) => reject(error));
      });
    },
    updateNotification(notification: Notification): Promise<Notification> {
      return new Promise((resolve, reject) => {
        NotificationService.updateNotification(notification)
          .then((updatedNotification: Notification) => {
            const ind = findIndex(this.notifications, { id: notification.id });
            if (ind > -1) {
              this.notifications[ind] = updatedNotification;
            }

            resolve(updatedNotification);
          })
          .catch((error) => reject(error));
      });
    },
    sendNotification(notificationId: number) {
      return new Promise((resolve, reject) => {
        NotificationService.sendNotification(notificationId)
          .then((sendedNotification: Notification) => {
            resolve(sendedNotification);
          })
          .catch((error) => reject(error));
      });
    },
    setNotificationInProcess(notificationId: number, currentValue: boolean) {
      const existedNotificationIndex = this.notifications.findIndex(
        (notification: Notification) => notification?.id === notificationId
      );
      if (existedNotificationIndex > -1) {
        this.notifications[existedNotificationIndex].inProcess = currentValue;
      }
    },
    deleteNotification(notificationId: number): Promise<string> {
      return new Promise((resolve, reject) => {
        NotificationService.deleteNotification(notificationId)
          .then((message: string) => {
            this.notifications = this.notifications.filter( notificaiton => notificaiton.id != notificationId);
            resolve(message);
          })
          .catch((error) => reject(error));
      });
    }
  }
});
